<template>
  <div :class="['roomLevel', { isFlex: isFlex }]">
    <v-select
      clearable
      :options="userTypeOptions"
      v-model="searchParam.userType"
      label="用户类别"
      @change="changeUserType"
    />
    <v-select
      clearable
      filterable
      v-if="searchParam.userType != null && searchParam.userType"
      :options="communitList"
      v-model="searchParam.communityId"
      @change="changeCommunit"
      :label="areaOptionsMap[searchParam.userType]"
    />
    <template
      v-if="searchParam.userType == 10 && searchParam.communityId != null"
    >
      <v-select
        v-for="(item, index) in levelList"
        :key="index"
        clearable
        filterable
        :multiple="false"
        :allowCreate="true"
        :options="item.roomList"
        v-model="item.roomId"
        @change="changeRoomId($event, item, index)"
        :label="item.value"
      />
    </template>
    <template
      v-if="searchParam.userType == 11 && searchParam.communityId != null"
    >
      <v-select
        clearable
        filterable
        v-if="building.length != 0"
        :options="building"
        v-model="searchParam.buildingId"
        @change="bindChange($event, 'building')"
        label="楼幢"
      />
      <v-select
        clearable
        filterable
        v-if="floor.length != 0"
        :options="floor"
        v-model="searchParam.floorId"
        @change="bindChange($event, 'floor')"
        label="楼层"
      />
      <v-select
        clearable
        filterable
        v-if="company.length != 0"
        :options="company"
        v-model="searchParam.companyId"
        @change="bindChange($event, 'company')"
        label="商户/公司"
      />
    </template>
    <template
      v-if="searchParam.userType == 21 && searchParam.communityId != null"
    >
      <v-select
        clearable
        filterable
        v-if="mechan.length != 0"
        :options="mechan"
        v-model="searchParam.mechanId"
        @change="bindChange($event, 'mechan')"
        label="机构名称"
      />
      <v-select
        clearable
        filterable
        v-if="singleMechan.length != 0"
        :options="singleMechan"
        v-model="searchParam.singleMechanId"
        @change="bindChange($event, 'singleMechan')"
        label="名称"
      />
    </template>
    <v-select
      clearable
      v-if="
        isShowRelation && searchParam.userType == 10 && searchParam.totalRoomId
      "
      :options="relationOptions"
      @change="changeRelation"
      v-model="searchParam.identity"
      label="与业主关系"
    />
  </div>
</template>

<script>
import { getCommunityListUrl, getLinkUrl } from "./api.js";
import {
  userTypeOptions,
  relationOptions,
  areaOptionsMap,
  areaOptions,
  filterRoomOptionsMap,
} from "./map";

export default {
  name: "roomLevel",
  data() {
    return {
      levelList: [],
      floor: [],
      building: [],
      company: [],
      mechan: [],
      singleMechan: [],
      areaOptionsMap,
      userTypeOptions,
      relationOptions,
      searchParam: {
        singleMechanId: "",
        mechanId: "",
        buildingId: "",
        floorId: "",
        companyId: "",
        spaceId: "",
        totalRoomId: "",
        communityId: null,
        identity: null,
        userType: null,
      },
      communitList: [],
      struct: "",
    };
  },
  props: {
    // 组件当前层级
    isFlex: {
      type: Boolean,
      default: false,
    },
    // 是否显示与业主关系
    isShowRelation: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {
    uploadData(spaceId, isLast) {
      let params = {
        userType: this.searchParam.userType,
        spaceId: spaceId,
        isLast: isLast,
        identity: this.searchParam.identity,
        communityId: this.searchParam.communityId,
      };
      this.$emit("change", params);
    },
    changeRelation() {
      this.uploadData(this.searchParam.totalRoomId);
    },
    bindChange(e, oData) {
      let spaceId = e;
      if (oData == "building") {
        this.company = [];
        this.floor = [];
        this.searchParam.companyId = null;
        this.searchParam.floorId = null;
        this.getLink(e, oData);
        this.uploadData(e);
      }
      if (oData == "floor") {
        this.company = [];
        this.searchParam.companyId = null;
        this.getLink(e, oData);
        this.uploadData(e);
      }
      if (oData == "company") {
        this.uploadData(e, 1);
      }
      if (oData == "mechan") {
        this.searchParam.singleMechanId = null;
        this.singleMechan = [];
        this.uploadData(e);
      }
      if (oData == "singleMechan") {
        this.uploadData(e, 1);
      }
    },

    resetRoom() {
      this.searchParam.spaceId = null;
      this.searchParam.totalRoomId = null;
      this.searchParam.singleMechanId = null;
      this.searchParam.mechanId = null;

      this.searchParam.buildingId = null;
      this.searchParam.floorId = null;
      this.searchParam.companyId = null;
      this.floor = [];
      this.building = [];
      this.company = [];
      this.mechan = [];
      this.singleMechan = [];
      this.levelList = [];
    },

    changeRoomId(e, oValue, index) {
      for (var i = 0; i <= this.levelList.length - 1; i++) {
        if (i > index) {
          this.levelList.splice(i);
          break;
        }
      }
      if (e && oValue.roomList[0] && oValue.roomList[0].struct) {
        this.getLink(e);
      }
      this.searchParam.totalRoomId =
        this.levelList[this.levelList.length - 1].roomId;
      if (e && oValue.roomList[0] && oValue.roomList[0].struct) {
        this.uploadData(this.searchParam.totalRoomId);
      } else {
        this.uploadData(this.searchParam.totalRoomId, 1);
      }
    },
    changeUserType() {
      this.resetRoom();
      this.searchParam.communityId = null;
      this.uploadData("");
      this.getCommunitList();
    },
    returnValue(value) {
      for (var i in filterRoomOptionsMap) {
        if (value.indexOf(i) != -1) {
          return i;
        }
      }
    },
    changeCommunit() {
      this.resetRoom();
      this.uploadData("");
      let spaceId = this.searchParam.communityId;
      if (this.searchParam.userType == 10) {
        this.getLink(spaceId, "community");
      }
      if (this.searchParam.userType == 11) {
        this.getLink(spaceId, "community");
      }
      if (this.searchParam.userType == 21) {
        this.getLink(spaceId, "mechanCommunity");
      }
    },
    async getLink(spaceId, oData) {
      if (!spaceId) {
        return;
      }
      let params = {
        spaceId: spaceId,
      };
      let res = await this.$axios.get(`${getLinkUrl}`, { params });
      if (res.code == 200) {
        let swithData = [];
        res.data.forEach((item) => {
          let obj = {};
          obj.label = item.communityName;
          obj.value = item.communityId;
          swithData.push(obj);
        });
        if (this.searchParam.userType == 10) {
          let struct;
          let swithData1 = [];
          res.data.forEach((item) => {
            let obj = {};
            struct = this.returnValue(item.communityName);
            obj.label = item.communityName;
            obj.value = item.communityId;
            obj.struct = item.struct;
            swithData1.push(obj);
          });
          let obj1 = { value: struct, roomList: swithData1, roomId: "" };
          this.levelList.push(obj1);
        }
        if (this.searchParam.userType == 11) {
          if (oData == "community") {
            this.building = swithData;
          }
          if (oData == "building") {
            this.floor = swithData;
          }
          if (oData == "floor") {
            this.company = swithData;
          }
        }
        if (this.searchParam.userType == 21) {
          if (oData == "mechanCommunity") {
            this.mechan = swithData;
          }
          if (oData == "mechan") {
            this.singleMechan = swithData;
          }
        }
      }
    },
    getCommunitList() {
      let params = {
        type: this.searchParam.userType,
      };
      this.communitList = [];
      this.$axios.get(`${getCommunityListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            let obj = {};
            obj.label = item.communityName;
            obj.value = item.communityId;
            this.communitList.push(obj);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.roomLevel {
  .v-control {
    margin: 0 10px 10px 0;
  }
  .company,
  .mech {
    display: flex;
  }
  .search {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 10px;
    .label {
      font-size: 14px;
      margin-right: 10px;
    }
    .input {
      display: flex;
      position: relative;
      border: 1px solid #dcdfe6;

      height: 40px;
      -webkit-appearance: none;

      background-image: none;
      border-radius: 4px;

      box-sizing: border-box;
      color: #606266;

      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 30px 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      align-items: center;
      .list {
        width: 100%;
        position: absolute;
        line-height: 20px;
        bottom: -310px;
        left: 0;
        height: 300px;
        overflow-y: auto;
        background: #fff;
        border: 1px solid #dcdfe6;
        z-index: 2;
        padding: 10px 0;
        box-sizing: border-box;
        .item {
          padding: 10px;
          font-size: 14px;
          color: #606266;
          &:hover {
            background-color: #f5f7fa;
          }
        }
      }
      & /deep/ .el-input__inner {
        border: none;
        height: 36px;
        box-sizing: border-box;
      }
      & /deep/ .el-input.is-disabled .el-input__inner {
        background: #fff;
      }
      img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 4px;
        margin: auto;
      }
    }
  }
}
.isFlex {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}
</style>
