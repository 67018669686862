//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
//获取房号审核列表
const getRoomHandleListUrl = `/spaceApi/space/getUserSpaceRelationList`;
//审核房号
const handleRoomUrl = `/spaceApi/space/examineUserSpaceRelation`;
//审核通过房号
const passRoomUrl = `/spaceApi/space/examineUserSpaceRelation`;
//驳回房号
const rejectRoomUrl = `/spaceApi/space/examineUserSpaceRelation`;
//获取房号
const getLinkUrl = `/gateway/hc-space/space/link`;
export {
  getCommunityListUrl,
  getRoomHandleListUrl,
  handleRoomUrl,
  passRoomUrl,
  rejectRoomUrl,
  getLinkUrl,
};
