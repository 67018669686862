import { mapHelper } from "@/utils/common.js";

const placeStatus = [
  {
    code: 0,
    label: "关闭",
  },
  {
    code: 1,
    label: "开启",
  },
  {
    code: 2,
    label: "删除",
  },
];

const placeOrderStatus = [
  {
    code: 1,
    label: "待付款",
  },
  {
    code: 2,
    label: "已审核",
  },
  {
    code: 3,
    label: "已取消",
  },
  {
    code: 4,
    label: "待接收",
  },
  {
    code: 5,
    label: "已关闭",
  },
  {
    code: 11,
    label: "待审核",
  },
];

const editTitleMap = {
  update: "编辑",
  create: "添加",
};
const appointWay = [
  {
    value: 1,
    label: "排班预约",
  },
  {
    value: 2,
    label: "电话联系",
  },
  {
    value: 3,
    label: "预约地址",
  },
  {
    value: 4,
    label: "文字说明",
  },
];

const { map: appointWayMap, setOps: appointWayOps } =
  mapHelper.setMap(appointWay);
export {
  editTitleMap,
  placeStatus,
  placeOrderStatus,
  appointWayMap,
  appointWayOps,
  appointWay,
};
